<template>
  <div
    class="row pt-1 pb-2"
    style="
    background: none;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25),
            0px 0px 41px 9px rgba(0, 0, 0, 0.11);
            border-radius: 10px;"
  >
    <div class="col">
      <div
        class="row mb-1"
      >
        <div class="col-lg-5 saj-text">
          <!-- kra name ->
          {{ KRA.description !== null ? 'ade data' : KRA.description !== null }} -->
          <div class="m-0 mt-1 mb-1">
            <feather-icon
              icon="CheckCircleIcon"
              size="22"
            />
            {{ $t ("Measurement") }}
            <!-- {{ isApprovedAt }} -->
          </div>
          <validation-provider
            #default="{ errors }"
            :name="$t('Measurement')"
            rules="required"
          >
            <b-form-textarea
              v-model="propMeasurement"
              placeholder=""
              rows="5"
              style="outline: none"
              :disabled="currentPart.id === 'evaluation' && isType !== 'result' && !(isNewTemp === 'newScore') || isSubmitted || isApprovedAt !== null || (roles.selectedRole === 4 && currentUserRole === 'Executive' && 'Top Management') ? true : false"
            />
            <small
              class="text-danger"
              style="font-family: Poppins"
            >{{ errors[0] }}</small>
          </validation-provider>
        </div>
        <div class="col-lg-5 saj-text">
          <div class="m-0 mt-1 mb-1">
            <feather-icon
              icon="CheckCircleIcon"
              size="22"
            />
            {{ $t ("Target") }}
          </div>
          <validation-provider
            #default="{ errors }"
            :name="$t('Target')"
            rules="required"
          >
            <b-form-textarea
              v-model="propTarget"
              placeholder=""
              rows="5"
              :disabled="currentPart.id === 'evaluation' && isType !== 'result' && !(isNewTemp === 'newScore') || isSubmitted || isApprovedAt !== null || (roles.selectedRole === 4 && currentUserRole === 'Executive' && 'Top Management')? true : false"
            />
            <small
              class="text-danger"
              style="font-family: Poppins"
            >{{ errors[0] }}</small>
          </validation-provider>
        </div>
        <div class="col-lg-2 saj-text">
          <div class="m-0 mt-1 mb-1">
            <feather-icon
              icon="CheckCircleIcon"
              size="22"
            />
            {{ $t ("Deadline") }}

          </div>
          <validation-provider
            #default="{ errors }"
            :name="$t('Deadline')"
            rules="required"
          >
            <div :style="currentPart.id === 'evaluation' && isType !== 'result' && !(isNewTemp === 'newScore') || isSubmitted || isApprovedAt !== null || (roles.selectedRole === 4 && currentUserRole === 'Executive' && 'Top Management') ? `background-color: #EEEEEE` : `background-color:#FFFFFF;`">

              <flat-pickr
                v-model="propDate"
                class="form-control"
                size="sm"
                :config="{ dateFormat: 'Y-m-d', altInput: true, altFormat: 'd-m-Y'}"
                :disabled="currentPart.id === 'evaluation' && isType !== 'result' && !(isNewTemp === 'newScore') || isSubmitted || isApprovedAt !== null ? true : false"
              />

            </div>
            <small
              class="text-danger"
              style="font-family: Poppins"
            >{{ errors[0] }}</small>
          </validation-provider>

        </div>
      </div>
      <!-- Planning Attachment -->
      <div class="row">
        <div class="col-lg saj-text">
          <div class="m-0 mb-2 d-flex flex-column">
            <b-form-file
              v-if="currentPart.id === 'setKra'"
              v-model="propAttachmentKra"
              class="saj-text"
              :disabled="isSubmitted || isApprovedAt !== null || (roles.selectedRole === 4 && currentUserRole === 'Executive' && 'Top Management') ? true: false"
              type="file"
              :browse-text="$t('Choose File')"
              :placeholder="$t('No file choosen')"
              @change="onFileChange"
            />
            <div
              class="d-flex mt-1"
            >
              <span
                v-if="currentPart.id === 'setKra'"
                style="margin-right: 5px;"
              >{{ $t("Uploaded File") }}: </span>
              <template v-if="isFileKra !== null && currentPart.id === 'setKra'">
                <a
                  :href="isFileKra.url"
                  target="_blank"
                  download
                > {{ isFileKra.name }}</a>
              </template>
              <template v-if="isFileKra === null && currentPart.id === 'setKra'">
                {{ $t("No File") }}
              </template>
            </div>
          </div>
        </div>
      </div>
      <!-- Planning Attachment End -->

    </div>
  </div>
</template>

<script>
import {
  BFormTextarea,
  BFormFile,

} from "bootstrap-vue"
import flatPickr from "vue-flatpickr-component"
import { mapGetters } from 'vuex'
import { ValidationProvider } from 'vee-validate'
import SAJToast from '@/component/saj-toastification.vue'

export default {
  components: {
    BFormTextarea,
    flatPickr,
    ValidationProvider,
    BFormFile,
  },
  props: {
    isSubmitted: {
      type: Boolean,
      default: false,
    },
    currentPart: {
      type: Object,
      default: null,
    },
    measurement: {
      type: String,
      default: null,
    },
    target: {
      type: String,
      default: null,
    },
    date: {
      type: String,
      default: null,
    },
    isFileKra: {
      type: Object,
      default: null,
    },
    isType: {
      type: String,
      default: null,
    },
    isNewTemp: {
      type: String,
      default: null,
    },
    isUserID: {
      type: String,
      default: null,
    },
    isApprovedAt: {
      type: null,
      default: null,
    },
  },
  data() {
    return {
      targetDate: null,
      currentUserRole: null,
      // propAttachmentKra: null,
    }
  },
  computed: {
    propMeasurement: {
      get() { return this.measurement },
      set(value) { this.$emit('update:measurement', value) },
    },
    propTarget: {
      get() { return this.target },
      set(value) { this.$emit('update:target', value) },
    },
    propDate: {
      get() { return this.date },
      set(value) { this.$emit('update:date', value) },
    },
    propAttachmentKra: {
      get() { return this.attachmentKra },
      set(value) { this.$emit('update:attachmentKra', value) },
    },
    ...mapGetters(['roles']),
  },
  mounted(){
    this.currentUser()
    // console.log("approvee", this.isApprovedAt)
  },
  methods: {
    currentUser(){
      const data = new FormData()
      this.$axios.get(`${this.$baseUrl}/supervisor/getEmployee?user_id=${this.isUserID}`, data).then(response => {
        this.currentUserRole = response.data.data.role_name
        // console.log("user yg tengah dibuka", this.currentUserRole)
      })
    },
    onFileChange(e) {
      // console.log('on file change', e.target.files[0].size)
      if (e.target.files.length !== 0){
        const fileSize = e.target.files[0].size / 1000

        if (fileSize > 5120){
          this.$toast(
            {
              component: SAJToast,
              props: {
                title: `${this.$t('File must less than 5 MB')}.`,
                icon: "XIcon",
                variant: "success",
                titleColor: "#000",
                iconBg: '#e80202',
              },
            },
            {
              position: "top-right",
              type: 'info',
            },
          )
        }
      }
    },
  },
}
</script>

<style>

</style>
