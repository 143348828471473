<template>
  <div
    class="row pt-1 pb-2"
    style="
    background: none;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25),
            0px 0px 41px 9px rgba(0, 0, 0, 0.11);
            border-radius: 10px;"
  >
    <div class="col">
      <div
        class="row mb-2"
      >
        <div class="col-lg saj-text">
          <div class="m-0 mt-1 mb-1">
            <feather-icon
              icon="CheckCircleIcon"
              size="22"
            />
            {{ $t ("Tracking (Evidence)") }}
          </div>
          <validation-provider
            #default="{ errors }"
            :name="$t('Tracking (Evidence)')"
            rules="required"
          >
            <b-form-textarea
              v-model="propTracking"
              :disabled="isSubmitted || isApprovedAt !== null|| (roles.selectedRole === 4 && currentUserRole === 'Executive' && 'Top Management') ? true: false"
              placeholder=""
              rows="5"
            />
            <small
              class="text-danger"
              style="font-family: Poppins"
            >{{ errors[0] }}</small>
          </validation-provider>
        </div>
        <div
          class="col-lg saj-text"
        >
          <div
            class="m-0 mt-1 mb-1"
          >
            <feather-icon
              icon="CheckCircleIcon"
              size="22"
            />
            {{ $t ("Mid-Year KPI Achievement") }}

          </div>
          <!-- {{ propAchievement === null ? 'kosong': propAchievement }} -->
          <!-- <b-form-textarea
            v-if="propAchievement === null && currentPart.id === 'evaluation'"
            v-model="AchievementEmpty"
            :disabled="currentPart.id === 'evaluation' || isSubmitted || isApprovedAt !== null || (roles.selectedRole === 4 && currentUserRole === 'Executive' && 'Top Management')? true : false "
            placeholder=""
            rows="5"
          /> -->
          <validation-provider
            #default="{ errors }"
            :name="$t('Mid-Year KPI Achievement')"
            rules="required"
          >
            <b-form-textarea
              v-if="propAchievement === null && currentPart.id === 'evaluation'"
              v-model="AchievementEmpty"
              :disabled="currentPart.id === 'evaluation' || isSubmitted || isApprovedAt !== null || (roles.selectedRole === 4 && currentUserRole === 'Executive' && 'Top Management')? true : false "
              placeholder=""
              rows="5"
            />
            <b-form-textarea
              v-else
              v-model="propAchievement"
              :disabled="currentPart.id === 'evaluation' || isSubmitted || isApprovedAt !== null || (roles.selectedRole === 4 && currentUserRole === 'Executive' && 'Top Management')? true : false "
              placeholder=""
              rows="5"
            />
            <small
              class="text-danger"
              style="font-family: Poppins"
            >{{ errors[0] }}</small>
          </validation-provider>
        </div>

        <div
          v-if="currentPart.id !== 'review'"
          class="col-lg saj-text"
        >
          <div class="m-0 mt-1 mb-1">
            <feather-icon
              icon="CheckCircleIcon"
              size="22"
            />
            {{ $t ("Year End KPI Achievement") }}

          </div>
          <validation-provider
            #default="{ errors }"
            :name="$t('Year End KPI Achievement')"
            rules="required"
          >
            <b-form-textarea
              v-model="propyearAchievement"
              :disabled="isSubmitted || isApprovedAt !== null || (roles.selectedRole === 4 && currentUserRole === 'Executive' && 'Top Management') ? true : false"
              placeholder=""
              rows="5"
            />
            <small
              class="text-danger"
              style="font-family: Poppins"
            >{{ errors[0] }}</small>
          </validation-provider>
        </div>

      </div>

      <div class="row">
        <div class="col-lg saj-text">
          <div class="m-0 mb-2 d-flex flex-column">
            <b-form-file
              v-if="currentPart.id !== 'evaluation'"
              v-model="propAttachment"
              class="saj-text"
              :disabled="isSubmitted || isApprovedAt !== null || (roles.selectedRole === 4 && currentUserRole === 'Executive' && 'Top Management') ? true: false"
              type="file"
              :browse-text="$t('Choose File')"
              :placeholder="$t('No file choosen')"
              @change="onFileChange"
            />
            <div
              class="d-flex mt-1"
            >
              <span
                v-if="currentPart.id !== 'evaluation'"
                style="margin-right: 5px;"
              >{{ $t("Uploaded File") }}: </span>
              <template v-if="isFile !== null && currentPart.id !== 'evaluation'">
                <a
                  :href="isFile.url"
                  target="_blank"
                  download
                > {{ isFile.name }}</a>
              </template>
              <template v-if="isFile === null && currentPart.id !== 'evaluation'">
                {{ $t("No File") }}
              </template>
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-lg saj-text">
          <div class="m-0 mb-2 d-flex flex-column">
            <b-form-file
              v-if="currentPart.id !== 'review'"
              v-model="propresultAttachment"
              class="saj-text"
              :disabled="isSubmitted || (roles.selectedRole === 4 && currentUserRole === 'Executive' && 'Top Management') ? true: false"
              type="file"
              :browse-text="$t('Choose File')"
              :placeholder="$t('No file choosen')"
              @change="onFileChange"
            />
            <div class="d-flex mt-1">
              <span
                v-if="currentPart.id !== 'review'"
                style="margin-right: 5px;"
              >{{ $t("Uploaded File") }}: </span>
              <template v-if="isFileResult !== null && currentPart.id !== 'review'">
                <a
                  :href="isFileResult.url"
                  target="_blank"
                  download
                > {{ isFileResult.name }}</a>
              </template>
              <template
                v-if="isFileResult === null && currentPart.id !== 'review'"
                class="bg-warning"
              >
                {{ $t("No File") }}
              </template>
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import {
  BFormTextarea,
  BFormFile,

} from "bootstrap-vue"
import { mapGetters } from 'vuex'
import SAJToast from '@/component/saj-toastification.vue'
import { ValidationProvider } from 'vee-validate'

export default {
  components: {
    BFormTextarea,
    BFormFile,
    ValidationProvider,
  },
  props: {
    isSubmitted: {
      type: Boolean,
      default: false,
    },
    currentPart: {
      type: Object,
      default: null,
    },
    tracking: {
      type: String,
      default: null,
    },
    achievement: {
      type: String,
      default: null,
    },
    yearachievement: {
      type: String,
      default: null,
    },
    date: {
      type: String,
      default: null,
    },
    attachment: {
      type: File,
      default: null,
    },
    resultattachment: {
      type: File,
      default: null,
    },
    isFile: {
      type: Object,
      default: () => ({
        name: '',
        url: '',
      }),
    },
    isFileResult: {
      type: Object,
      default: () => ({
        name: '',
        url: '',
      }),
    },
    isUserID: {
      type: String,
      default: null,
    },
    // eslint-disable-next-line vue/prop-name-casing
    achievement_to_date: {
      type: String,
      default: null,
      currentUserRole: null,

    },
    isApprovedAt: {
      type: null,
      default: null,
    },
    isNewTemp: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      currentUserRole: null,
      AchievementEmpty: '-',
    }
  },
  computed: {
    propTracking: {
      get() { return this.tracking },
      set(value) { this.$emit('update:tracking', value) },
    },
    propAchievement: {
      get() { return this.achievement },
      set(value) { this.$emit('update:achievement', value) },
    },
    propyearAchievement: {
      get() { return this.yearachievement },
      set(value) { this.$emit('update:yearachievement', value) },
    },
    propAchievement_to_date: {
      get() { return this.achievement_to_date },
      set(value) { this.$emit('update:achievement_to_date', value) },
    },
    propAttachment: {
      get() { return this.attachment },
      set(value) { this.$emit('update:attachment', value) },
    },
    propresultAttachment: {
      get() { return this.resultattachment },
      set(value) { this.$emit('update:resultattachment', value) },
    },
    ...mapGetters(['roles']),
  },
  mounted(){
    this.currentUser()
  },
  methods: {
    currentUser(){
      const data = new FormData()
      this.$axios.get(`${this.$baseUrl}/supervisor/getEmployee?user_id=${this.isUserID}`, data).then(response => {
        this.currentUserRole = response.data.data.role_name
        // console.log("user yg tengah dibuka", this.currentUserRole)
      })
    },
    onFileChange(e) {
      // console.log('on file change', e.target.files[0].size)
      if (e.target.files.length !== 0){
        const fileSize = e.target.files[0].size / 1000

        if (fileSize > 5120){
          this.$toast(
            {
              component: SAJToast,
              props: {
                title: `${this.$t('File must less than 5 MB')}.`,
                icon: "XIcon",
                variant: "success",
                titleColor: "#000",
                iconBg: '#e80202',
              },
            },
            {
              position: "top-right",
              type: 'info',
            },
          )
        }
      }
    },
  },
}
</script>

<style>

</style>
